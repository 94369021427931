import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { StaticQuery, graphql, Link } from "gatsby";
import MenuItemDropdown from "./MenuItemDropdown";
import ContactFormModal from "./ContactFormModal";
import Logo from "./logo-svg";
import Button from "./atoms/Button";
import { ChevronLeft, ChevronDown, X } from "react-feather";
import { ArrowRight, BadgeCheck, ListOrdered, Target, TimerReset, TrendingUp, Users2 } from "lucide-react";


const featureList = [
  {
    item: (
      <>
        Family Owned
        <span className="hidden md:inline"> & Operated</span>
      </>
    ),
    icon: Users2,
  },
  {
    item: (
      <>
        Licensed & Insured 
      </>
    ),
    icon: BadgeCheck,
  },
  {
    item: (
      <>
        Free Estimates
      </>
    ),
    icon: ListOrdered,
  },
]


function Navbar({ headerTransparent = false, shadow = true }) {
  const companyname = "Pro Tile & Remodeling";
  const description = "desc";
  const slogan = "#1 Renovation Company Greenville";
  const subtagline = "Residential Renovation Experts";
  const phonenumber = "(864) 415-4509";
  const mainnavigationstyle = "inline";

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [contactFormOpen, setContactFormOpen] = useState(false);

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.classList.add("menu-expanded");
    }

    return function cleanup() {
      document.body.classList.remove("menu-expanded");
    };
  }, [mobileMenuOpen]);

  const MenuItem = ({ item, source, headerTransparent }) => {
    let itemId = item.id;
    let title = item.name;
    let slug = item.url;

    if (item.submenu && item.submenu.length > 0) {
      return (
        <MenuItemDropdown item={item} label={title} type={1} source={source} headerTransparent={headerTransparent} />
      );
    }
    return (
      <Link
        to={`${slug && slug.length < 2 ? "/" : slug}`}
        className={`${
          source && source == "mobilemenu"
            ? "block py-4 px-8 border-b border-gray-300"
            : `p-3 border-transparent hover:bg-gray-100 rounded-lg ${
                headerTransparent
                  ? "text-white hover:border-brand-300 hover:text-brand-200"
                  : "text-gray-700 hover:text-gray-900    "
              }`
        }  font-semibold text-[15px] font-display text-base   transition-colors duration-150 ease-in button-active-bhvr`}
        activeClassName="!text-black hover:text-black"
      >
        {item.name}
      </Link>
    );
  };

  

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQueryInNav {
          site {
            siteMetadata {
              title
              description
              companyInfo {
                phone
                email
                addressLine1
                addressLine2
                city
                state
                zip
                license
              }
            }
          }
          allContentfulNavigation(filter: { name: { eq: "Main Navigation" } }) {
            edges {
              node {
                id
                name
                menuItems {
                  id
                  name
                  title
                  url
                  openInNewWindow
                  submenu {
                    id
                    name
                    menuItems {
                      id
                      name
                      title
                      url
                      openInNewWindow
                    }
                  }
                }
                campaign
              }
            }
          }
        }
      `}
      render={(data) => {
        const items = data.allContentfulNavigation.edges[0].node.menuItems;
        return (
          <>
         
         <div className={`${"bg-gray-50 text-gray-700"}  font-display  block relative z-20`}>
                <div className="flex justify-center md:justify-between  font-display mx-auto py-3 text-xs md:text-sm max-w-7xl  px-4 md:px-6">
                  <span className="block text-center md:text-left font-medium">Proudly serving Greenville, SC and surrounding areas.</span>
                  <span className="hidden md:block text-center md:text-left font-medium">{subtagline}</span>
                </div>
              </div>
            <div className={`sticky top-0 z-[20]  ${headerTransparent ? "header--transparent-main" : ""}`}>
            
              <div
                className={`${headerTransparent ? " md:bg-transparent bg-transparent md:-mb-[90px]  " : "bg-white"} ${
                  !shadow ? "" : "md:shadow-sm nav-shadow"
                } relative z-20 `}
              >
                <div className="flex justify-between items-center px-4 md:px-6 py-4 lg:py-0 md:space-x-10 max-w-7xl mx-auto md:h-[90px]">
                <div className="flex items-center">  <div className={`mr-2 pr-2 border-r -my-2 md:hidden sm:pr-0 sm:m-0`}>
                    <button
                      type="button"
                      onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                      className={`${
                        headerTransparent
                          ? "text-white"
                          : " text-gray-600 hover:bg-gray-200  focus:text-gray-700  focus:bg-gray-50"
                      } text-center p-2 rounded-lg  focus:outline-none  transition duration-150 ease-in`}
                    >
                      {mobileMenuOpen ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="h-6 w-6 mx-auto"
                        >
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="h-6 w-6 mx-auto"
                        >
                          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
                        </svg>
                      )}

                      <span className="block uppercase font-normal tracking-wide" style={{ fontSize: ".5rem" }}>
                        Menu
                      </span>
                    </button>
                  </div>
                
                  <Link to="/" className="block w-28 md:w-32  p-2 shrink-0" title={companyname}>
                    <Logo classes={headerTransparent ? "text-white" : "text-brand-500"} />
                  </Link>
                  </div>

                  {mainnavigationstyle == "inline" ? (
                    <div className="items-center font-display md:space-x-4 hidden lg:flex">
                      {items.map((item, i) => {
                        return (
                          <MenuItem key={item.id} item={item} type={item.type} headerTransparent={headerTransparent} />
                        );
                      })}
                    </div>
                  ) : null}

                  <div className="flex-1"/>

                  {mainnavigationstyle == "stacked" ? (
                    <div className="hidden lg:block font-display text-center">
                      <span className="font-semibold block">{slogan}</span>
                      <span className="font-normal text-sm text-gray-800 block">{subtagline}</span>
                    </div>
                  ) : null}

                  <div className="  font-display shrink-0">
                    <div className="flex flex-col sm:flex-row items-center">
                      <div className=" sm:pr-8 text-center">
                        <a
                          className={`mb-1 block font-bold text-base sm:text-xl lg:text-xl  tracking-tight ${
                            headerTransparent ? "text-white" : "text-gray-900"
                          }`}
                          href={`tel:${phonenumber}`}
                        >
                          {phonenumber}
                        </a>
                      </div>
                      <div>
                        <Button
                          onClick={() => setContactFormOpen(true)}
                          className="px-3 py-2   sm:px-4 sm:py-2 lg:py-3 lg:px-5   button-active-bhvr whitespace-no-wrap flex items-center block cursor-pointer transition duration-150 ease-in-out bg-brand-600 focus:bg-brand-700 hover:bg-brand-700 focus:outline-none font-semibold  text-white font-display"
                        >
                        <span className="text-[13px] sm:text-sm font-medium">Free Estimate</span> <ArrowRight className="h-3 w-3 sm:w-4 sm:h-4 ml-1"/>
                        </Button>
                        <ContactFormModal modalOpen={contactFormOpen} onModalClose={setContactFormOpen} />
                      </div>
                    </div>
                  </div>

                  
                </div>

                {/* <Transition
                  show={mobileMenuOpen}
                  enter="duration-200 ease-out"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100 ease-in"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                  className={`absolute inset-x-0 transition transform origin-top-right z-50 ${mainnavigationstyle == 'stacked' ? 'md:hidden' : 'lg:hidden'}`}
                  style={{ top: '100%' }}
                >
                  <div className=" shadow-lg font-display">
                    <div className="shadow-xs bg-white">

                      <div>
                        {items.map((item, i) => {
                          return <MenuItem key={i} item={item} type={item.type} source={'mobilemenu'} />;
                        })}
                      </div>
                    </div>
                  </div>
                </Transition> */}
              </div>

              {mainnavigationstyle == "stacked" ? (
                <div className="relative bg-white text-white md:shadow-sm md:border-none z-10 hidden md:block font-display">
                  <div className="flex items-center px-4 md:px-6 md:space-x-10 container mx-auto ">
                    {items.map((item, i) => {
                      return <MenuItem key={i} item={item} type={item.type} />;
                    })}
                  </div>
                </div>
              ) : null}
            </div>
            {/* <div className={`sticky top-0  z-20 md:hidden py-1 px-2 ${headerTransparent ? "-mb-48" : "bg-brand-600"}`}>
              <div
                className={` shadow-sm md:border-none ${
                  headerTransparent
                    ? "shadow-lg bg-brand-600/90 backdrop-blur-lg rounded-2xl text-brand-900 "
                    : "bg-brand-600  text-brand-900 "
                }`}
              >
                <div className="flex  items-center justify-between px-4 md:px-6  py-3 container mx-auto ">
                  <div>
                    <p className="mb-0 text-white font-display hidden sm:block">Give Us A Call Today</p>
                    <a className={`block font-semibold text-xl font-display text-white`} href={`tel:${phonenumber}`}>
                      {phonenumber}
                    </a>
                  </div>
                  <Button onClick={() => setContactFormOpen(true)} className="bg-brand-800 py-2.5 px-4 -my-1">
                    Free Estimate
                  </Button>
                </div>
              </div>
            </div> */}

            <Transition.Root show={mobileMenuOpen} as={Fragment}>
              <Dialog as="div" className="relative z-20" onClose={setMobileMenuOpen}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black  bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                  <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
                      <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="-translate-x-full "
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                      >
                        <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                          <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                            <div className="px-4 sm:px-6">
                              <div className="flex items-start justify-between">
                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                  <Link to="/" className="block w-36 md:w-40  p-2 shrink-0" title={companyname}>
                                    <Logo classes={headerTransparent ? "text-brand-500" : "text-brand-500"} />
                                  </Link>
                                </Dialog.Title>
                                <div className="ml-3 flex h-8 items-center">
                                  <button
                                    type="button"
                                    className="rounded-lg text-brand-700 hover:text-gray-500 focus:outline-none  "
                                    onClick={() => setMobileMenuOpen(false)}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <X className="h-6 w-6" aria-hidden="true" />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="relative mt-6 font-display">
                              {" "}
                              <div>
                                {items.map((item, i) => {
                                  return <MenuItem key={i} item={item} type={item.type} source={"mobilemenu"} />;
                                })}
                              </div>
                            </div>
                            <div className="p-6">
                            <div  >
                        <a
                          className={`mb-3 block font-bold  text-xl  lg:text-2xl `}
                          href={`tel:${phonenumber}`}
                        >
                          {phonenumber}
                        </a>
                      </div>
                              
                            <Button to="/contact">Get a FREE Estimate <ArrowRight size={18}/></Button></div>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </div>
              </Dialog>
            </Transition.Root> 
            
            <div className="bg-gradient-to-r from-blue-600 to-blue-500 text-gray-800">
        {featureList && (
          <div className="mx-auto flex  items-center justify-center gap-3 py-3 md:gap-5">
            {featureList.map((item, i) => {
              return (
                <>
                  {" "}
                  {i > 0 && <span className="text-blue-300">/</span>}
                  <div className="flex  flex-row  items-center" key={i}>
                    {item.icon && (
                      <item.icon
                        className="white-600 mr-2 hidden shrink-0 text-white sm:block"
                        size={16}
                      />
                    )}

                    <p className="text-left  text-xs sm:text-sm font-medium leading-6 text-white  ">
                      {item.item}
                    </p>
                  </div>
                </>
              )
            })}
          </div>
        )}
      </div>
      
  
          </>
        );
      }}
    />
  );
}

export default Navbar;
