import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";

function ServicesOffered() {
  const services = [
    { title: "Full Home Remodeling", link: "full-home-remodeling-greenville" },
    { title: "Kitchen Remodeling", link: "greenville-kitchen-remodeling" },
    { title: "Bathroom Remodeling", link: "bathroom-remodeling-contractor-greenville" },
    { title: "Tile Installation & Replacement", link: "greenville-tile-installation-&-replacement" },
    { title: "Flooring Installation", link: "flooring-installation-and-replacement-greenville" },
    { title: "Siding Installation", link: "siding-installation-greenville" },  
  ];
  return (
    <div>
      <div>
        <div className="lg:mt-0 mt-6">
          <h4 className="text-sm leading-5 font-bold tracking-wider text-gray-700 uppercase font-display">Services</h4>

          <ul className="mt-2 leading-relaxed p-0  list-none space-y-2">
            {services.map((service, i) => {
              const { title, link } = service;
              return (
                <li key={i} className="pb-1 text-base leading-6 text-gray-700 flex items-center">
                  <svg
                    className="text-brand-500 w-5 h-5 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>

                  <Link to={`/${link}/`} title={`${title} Greenville`}>
                    {title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ServicesOffered;
